import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ReactIframe from 'react-iframe';
export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    university: '',
    email: '',
    message: '',
    phoneNumber: '',
    primaryInterest: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation logic
    if (
      formData.name.trim() === '' ||
      formData.email.trim() === '' ||
      formData.university.trim() === '' ||
      formData.primaryInterest.trim() === '' ||
      formData.message.trim() === ''
    ) {
      setError('Please fill in all fields.');
      return; // Prevent submission
    }

    setError('');
    setSuccess(false);

    try {
      setLoading(true);

      const response = await axios.post('https://browse-index.com/server/contact.php', formData);

      setFormData({
        name: '',
        university: '',
        email: '',
        message: '',
        phoneNumber: '',
        primaryInterest: ''
      });

      setLoading(false);
      setError('');
      setSuccess(true);
      console.log(response);
    } catch (error) {
      setError('An error occurred. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Wrapper id="contact">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Get in touch</h1>
            <p className="font20">
              Fill out the form below, as we'd love to talk with you to see how we could collaborate together and support your project.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: '30px' }}>
            <div>
            
              <ReactIframe 
          url="https://forms.office.com/r/12ckH8hgVH?embed=true/"
          width="300px"
          height="200px"
        />
           
              <iframe
                width="100%"
                height="480px"
                src="https://forms.office.com/r/12ckH8hgVH?embed=true"
                frameBorder="0"
                marginWidth="0"
                marginHeight="0"
                style={{ border: 'none', maxHeight: '100vh', width: '100%' }}
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                msallowfullscreen="true"
              ></iframe>

              <SubmitWrapper className="flex">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="bo"
                  disabled={loading}
                >
                  {loading ? 'Processing...' : 'Submit'}
                </button>
              </SubmitWrapper>
              <br />
              {error && <p className="red">{error}</p>}
              {success && <p className="green">Message sent successfully!</p>}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 100px;
  @media (max-width: 860px) {
    margin-top: 50px;
  }
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const SubmitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
