import React from "react";
import styled from "styled-components";
// Components
import { Link } from 'react-router-dom';

// Assets
import Ms from '../../assets/img/project.jpg';
import FullButton from "../Buttons/FullButton";

export default function ProjectHeader() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font40">Industry Projects.</h1>
          <HeaderP className="font20">
            Employers are continuously looking for new hires with the right skills and a global mindset to push their business and impact goals to the next level. Be among the best by acquiring the right skills and experience through real world project work powered by indEx
          </HeaderP>
          <BtnWrapper>

            <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>

              <div style={{ width: "190px", marginLeft: "15px" }}>
                <Link to="https://forms.office.com/pages/responsepage.aspx?id=MzaqMwwVuEO_79hoPuISdRvB_s8Bp9RDv_uQ1IfPWgtUNTZZQlIzUDRSNUMyRVZNWDRTVldHRFFURi4u&embed=true&route=shorturl">
                  <FullButton title="Submit project" border />
                </Link>
              </div>
            </ButtonsRow>
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <img src={Ms} alt="ms" width="700px" />
        </ImageWrapper>
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 180px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 90px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;